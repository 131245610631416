export const ADMIN = "Super Admin";
//export const ATLAS_URI = "http://localhost:8000";
// export const ATLAS_URI = "http://43.204.108.170:5000";
// export const ATLAS_URI = "https://8114-103-112-33-217.ngrok-free.app";
//export const ATLAS_URI = "https://dev-back.absoluhealthcare.com";
// export const ATLAS_URI = "http://65.0.170.118:5000";
//export const ATLAS_URI = "https://stage-back.absoluhealthcare.com";
// export const ATLAS_URI = "http://13.233.246.89:5000";
// export const ATLAS_URI = "http://172.31.47.13:5000";
// export const ATLAS_URI = "http://3.6.91.84:5000";
// export const ATLAS_URI = "http://65.1.134.119:5000";
// export const ATLAS_URI = "http://15.206.89.127:5000";
export const ATLAS_URI = "https://team-back.absoluhealthcare.com";

//export const ATLAS_URI = process.env.REACT_APP_API_URL;

export const occupationList = [
  "Self Employed",
  "Full-time Job",
  "Part-time Job",
  "Business",
];
export const paymentTypeList = ["Cash", "Cheque", "Bank Transfer"];

export const CALLER_ID = "+18583384081";
export const INDIA_CALLER_ID = "+918035735490";
// export const CALLER_ID="+17735988978"
// export const INDIA_CALLER_ID="+17735988978"
export const CALL_METHOD = "WebRTC"; //IVR or WebRTC
export const ENDPOINT_PASSWORD = "welcome";
